import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { authService } from "../../services/auth.service";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import URL from "../../constants/routesURL";
import Logo from "../../assests/images/login/logo.png";
import ForgotPasswordEmail from "./forgotPasswordEmail";
import VerifyOtp from "./forgotPasswordverifyOtp";
import ChangePassword from "./changePassword";
import "./style.scss";

// Main ForgotPassword Component
function ForgotPassword() {
    const [step, setStep] = useState(1);
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [seconds, setSeconds] = useState(60);
    const [isCheckPassword, setIsCheckPassword] = useState(false);
    const [token, setToken] = useState("");
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location.state?.step === 3 && location.state?.email && location.state?.access_token) {
            setEmail(location.state.email);
            setToken(location.state.access_token);
            setIsCheckPassword(true)
            setStep(3);
        }
        let timer;
        if (seconds > 0) {
            timer = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);
        } else if (seconds === 0) {
            clearInterval(timer);
        }

        return () => clearInterval(timer);
    }, [location.state, seconds]);

    const handleEmailSubmit = async (values) => {
            try {
                const { data } = await authService.forgotPassword(values);
                setSeconds(60);
                setEmail(values.email)
                setStep(2);
            } catch (error) {
                console.log(error);
            }
    };

    const handleResendOtp = async (values) => {
        if (seconds === 0) {
            try {
                const { data } = await authService.forgotPassword(values);
                setSeconds(60);
                setEmail(values.email)
                setStep(2);
            } catch (error) {
                console.log(error);
            }
        }

    };

    const handleOTPSubmit = async (values) => {
        try {
            const payload = {
                email: email,
                otp: values.otp
            }
            setLoading(true)
            const { data } = await authService.forgotPasswordVerifyOtp(payload);
            setToken(data.token)
            setStep(3);
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    };

    const handleChangePasswordSubmit = async (values) => {
        try {
            const payload = {
                token: token,
                new_password: values.confirmPassword,
                email: location.state?.email
            }
            const result = await authService.forgotPasswordChange(payload);
            if (result.message == "Successful") {
                toast.success("Your password has been changed successfully")
            }
            navigate(URL.LOGIN);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <div className="ForgotPassword_page">
                <Container fluid>
                    <Row>
                        <Col md={4} className="offset-md-4">
                            <div className="form_align">
                                <div className="text-center">
                                    <img src={Logo} alt="Logo" />
                                </div>
                                <h1 className={isCheckPassword ? "small-title" : ""}>
                                {isCheckPassword ? "Create New Password" : "Forgot Password"}</h1>
                                {step === 1 && <ForgotPasswordEmail onSubmit={handleEmailSubmit} />}
                                {step === 2 && <VerifyOtp onSubmit={handleOTPSubmit} loading={loading} resendOtp={handleResendOtp} email={email} seconds={seconds} />}
                                {step === 3 && <ChangePassword onSubmit={handleChangePasswordSubmit} />}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default ForgotPassword;
