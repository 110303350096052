const generateProfileDetailsInitialValue = (value) => {
  return {
    name: value?.name || "",
    email: value?.email || "",
    phone_number: formatPhoneNumber(value?.phone_number || ""),
    organization_fax: formatPhoneNumber(value?.organization_fax || ""),
    organization_rep_first_name: value?.organization_rep_first_name || "",
    organization_rep_last_name: value?.organization_rep_last_name || "",
    organization_rep_phone: formatPhoneNumber(
      value?.organization_rep_phone || ""
    ),
    organization_rep_email: value?.organization_rep_email || "",
    street: value?.street || "",
    suite_unit: value?.suite_unit || "",
    zip: value?.zip || "",
    city: value?.city || "",
    state: value?.state || "",
  };
};

const generateClinicProfileDetailsInitialValue = (value) => {
  return {
    name: value?.name || "",
    email: value?.email || "",
    phone_number: formatPhoneNumber(value?.phone_number || ""),
    clinic_extension: value?.clinic_extension || "",
    clinic_fax: formatPhoneNumber(value?.clinic_fax || ""),
    clinic_rep_name: value?.clinic_rep_name || "",
    clinic_rep_phone: formatPhoneNumber(value?.clinic_rep_phone || ""),
    clinic_rep_email: value?.clinic_rep_email || "",
    street: value?.street || "",
    suite_unit: value?.suite_unit || "",
    zip: value?.zip || "",
    city: value?.city || "",
    state: value?.state || "",
  };
};

const generateDoctorProfileDetailsInitialValue = (value) => {
  return {
    first_name: value?.first_name || "",
    last_name: value?.last_name || "",
    email: value?.email || "",
    phone_number: formatPhoneNumber(value?.phone_number || ""),
    doctor_fax: formatPhoneNumber(value?.doctor_fax || ""),
    street: value?.street || "",
    suite_unit: value?.suite_unit || "",
    zip: value?.zip || "",
    city: value?.city || "",
    state: value?.state || "",
    clinics: value?.clinics || [],
  };
};

const downloadCSV = (fileName, data) => {
  const blob = new Blob([data], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  a.download = `${fileName}.csv`;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
};

const handleDataSelectionForExport = (type, dataToExport, data, selectedId) => {
  if (type === "All") {
    if (dataToExport.length === data.length) {
      return [];
    } else {
      const allDataId = data.map((d) => d.id);
      return allDataId;
    }
  } else {
    if (dataToExport.includes(selectedId)) {
      return dataToExport.filter((id) => id !== selectedId);
    } else {
      return [...dataToExport, selectedId];
    }
  }
};

function formatPhoneNumber(phoneNumber, action = "", originalNumber) {
  // Remove non-digit characters
  const cleaned = phoneNumber.replace(/\D/g, "");

  // Apply the phone number format dynamically
  let formatted = "";
  if (action === 'deleteContentBackward') {
    formatted = originalNumber
  } else {
    for (let i = 0; i < cleaned.length; i++) {
      if (i === 0) {
        formatted += "(" + cleaned[i];
      } else if (i === 2) {
        formatted += cleaned[i] + ") ";
      } else if (i === 5) {
        formatted += cleaned[i] + "-";
      } else {
        formatted += cleaned[i];
      }
    }
  }
  return formatted;
}

function formatDate(inputDate) {
  // Create a Date object from the input
  const date = new Date(inputDate);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
      return 'Invalid date'; // Return error message if the date is invalid
  }

  // Define an array of month names in uppercase
  const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

  // Get the month from the date (0-11)
  const month = months[date.getMonth()];

  // Get the day from the date and pad with zero if necessary
  const day = date.getDate().toString().padStart(2, '0');

  // Get the year from the date
  const year = date.getFullYear();

  // Return the formatted date
  return `${month} ${day}, ${year}`;
}

function formatDateToCustomFormatWithOffset(inputDate) {
  const date = new Date(inputDate);

  if (isNaN(date.getTime())) {
    return 'Invalid date';
  }
  date.setHours(date.getUTCHours() + 5);
  date.setMinutes(date.getUTCMinutes() + 30);

  const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
  const month = months[date.getMonth()];
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${month} ${day}, ${year}`;
}


// Example usage:
// console.log(formatDate("2024-09-22")); // Outputs: AUG 01, 2024
// console.log(formatDate("8/1/2024"));   // Outputs: AUG 01, 2024
// console.log(formatDate("Aug 1, 2024"));// Outputs: AUG 01, 2024
// console.log(formatDate("22-12-1998"));// Outputs: AUG 01, 2024


function profileDetailsHandleChange(e) {
  const action = e.nativeEvent.inputType;
  const input = e.target.value;
  if (e.target.name === "zip") {
    //Limit to a maximum of 9 digits
    let limitedInput = input.replace(/\D/g, "").slice(0, 9);

    // Add hyphen after the sixth digit
    if (limitedInput.length > 5) {
      limitedInput = `${limitedInput.slice(0, 5)}-${limitedInput.slice(5, 9)}`;
    }
    return limitedInput;
  } else {
    // Limit to a maximum of 10 digits, excluding hyphens
    const limitedInput = input.replace(/[^\d]/g, "").slice(0, 10);
    return formatPhoneNumber(limitedInput, action, input);
  }
}

const generateDoctorProfessionalDetailsInitialValue = (value) => {
  return {
    years: value?.years || 0 ,
    months: value?.months || 0,
    services_offered:  value?.services_offered ||[],
    languages_spoken: value?.languages_spoken || [],
  };
};

const generateOrganisationProfessionalDetailsInitialValue = (value) => {
  return {
    languages_spoken: value?.languages_spoken || [],
  };
};

const generateClinicProfessionalDetailsInitialValue = (value) => {
  return {
    languages_spoken: value?.languages_spoken || [],
  };
};




export {
  generateProfileDetailsInitialValue,
  generateClinicProfileDetailsInitialValue,
  generateDoctorProfileDetailsInitialValue,
  downloadCSV,
  handleDataSelectionForExport,
  formatPhoneNumber,
  profileDetailsHandleChange,
  formatDate,
  generateDoctorProfessionalDetailsInitialValue,
  generateOrganisationProfessionalDetailsInitialValue,
  generateClinicProfessionalDetailsInitialValue,
  formatDateToCustomFormatWithOffset
};
