import "./style.scss";
import { Dropdown } from "react-bootstrap";
import Notification from "../../assests/images/header/Notification.svg";
import User from "../../assests/images/header/user.png";
import More from "../../assests/images/header/More.svg";
import { useLocation, useNavigate } from "react-router-dom";
import headerNames, { headerRoles } from "./headerNames";
import { useContext } from "react";
import URL from "../../constants/routesURL";
import { Store } from "../../store/Store";
import { OrganisationService } from "../../services/Organisation.service";
import {clinicService} from "../../services/clinic.service"
import { Type } from "../../constants/storeAction.constants";
import { generateProfileDetailsInitialValue, generateClinicProfileDetailsInitialValue, generateDoctorProfileDetailsInitialValue } from "../../utils/helperFunction"
import {roles} from "../../constants/common.constants"
import { doctorService } from "../../services/doctor.service";
function Header() {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(Store);
  const { userInfo } = state;
  const { pathname } = useLocation();
  const isPathinHeaderNames = Object.keys(headerNames).includes(pathname);
  const page = isPathinHeaderNames
    ? headerNames[pathname]
    : pathname.split("/")[1] || "";

  const currentUser = headerRoles[userInfo?.user_type];


  const handleEditProfile = async (id) => {
    if (userInfo.user_type === roles.organization) {
      try {
        // setLoading(true);
        const { data } = await OrganisationService.getOrganisationClinic(id);
        // setLoading(false);
        // setShowDetails(true);
        dispatch({
          type: Type.ADD_EDIT_ORGANISATION_STEP_1,
          payload: generateProfileDetailsInitialValue(data),
        });
        dispatch({ type: Type.EDIT_ORGANISATION_DETAILS, payload: data });
        navigate(URL.PROFILE.EDIT);
      } catch (err) {
        // setLoading(false);
        console.log(err);
      }
    } else if (userInfo.user_type === roles.clinic) {
      try {
        // setLoading(true);
        const { data } = await clinicService.getClinicDetails(id);
        // setLoading(false);
        // setShowDetails(true);
        dispatch({
          type: Type.ADD_EDIT_CLINIC_STEP_1,
          payload: generateClinicProfileDetailsInitialValue(data),
        });
        dispatch({ type: Type.EDIT_CLINIC_DETAILS, payload: data });
        navigate(URL.PROFILE.EDIT);
      } catch (err) {
        // setLoading(false);
        console.log(err);
      }
    } else if (userInfo.user_type === roles.doctor) {
      try {
        const data = await doctorService.getDoctorDetails(id);
        dispatch({
          type: Type.ADD_EDIT_DOCTOR_STEP_1,
          payload: generateDoctorProfileDetailsInitialValue(data),
        });
        dispatch({ type: Type.EDIT_DOCTOR_DETAILS, payload: data });
        navigate(URL.PROFILE.EDIT);
      } catch (err) {
        console.log(err);
      }
    }else {
      return
    }

  };
  // const handleEditProfile = () => {
  //   navigate(URL.PROFILE.EDIT);
  // };

  return (
    <div>
      <div className="header_menu">
        <div className="header_submenu">
          <ul className="d-inline-block">
            <li>home</li>
            <li className="pe-3 ps-3 arrow-icon"></li>
            <li>{page}</li>
          </ul>
          <div className="text-right d-inline-block">
            <ul className="right_menu">
              <li>
                <img src={Notification} alt="Notification" />
              </li>
              <li>
                {/* <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-user"> */}
                    <div className="d-inline-block ps-3">
                      <img src={userInfo?.img || User} alt="User" />
                    </div>
                    <div className="profile-name d-inline-block ps-3">
                      <h3>{userInfo?.name || "No Name "}</h3>
                      <p className="emailHeader">{currentUser}</p>
                    </div>
                  {/* </Dropdown.Toggle>
                  <Dropdown.Menu className="custom-dropdown-menu">
                    <Dropdown.Item onClick={() => handleEditProfile(userInfo?.id)} className="custom-dropdown-item">
                      Edit Profile
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}
              </li>
              {userInfo.user_type !== roles.admin && userInfo.user_type !== roles.superAdmin &&
                <li>
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      <img src={More} alt="More" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="custom-dropdown-menu">
                      <Dropdown.Item onClick={() => handleEditProfile(userInfo?.id)} className="custom-dropdown-item">Edit Profile</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              }
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
