export const Type = {
  USER_LOGIN: "USER_LOGIN",
  ADD_ORGANISATION_STEP_1: "ADD_ORGANISATION_STEP_1",
  REMOVE_ORGANISATION_STEP_1: "REMOVE_ORGANISATION_STEP_1",
  EDIT_ORGANISATION_DETAILS: "EDIT_ORGANISATION_DETAILS",
  ADD_EDIT_ORGANISATION_STEP_1: "ADD_EDIT_ORGANISATION_STEP_1",
  REMOVE_EDIT_ORGANISATION_DETAILS: "REMOVE_EDIT_ORGANISATION_DETAILS",
  ADD_CLINIC_STEP_1: "ADD_CLINIC_STEP_1",
  REMOVE_CLINIC_STEP_1: "REMOVE_CLINIC_STEP_1",
  EDIT_CLINIC_DETAILS: "EDIT_CLINIC_DETAILS",
  ADD_EDIT_CLINIC_STEP_1: "ADD_EDIT_CLINIC_STEP_1",
  REMOVE_EDIT_CLINIC_DETAILS: "REMOVE_EDIT_CLINIC_DETAILS",
  ADD_DOCTOR_STEP_1: "ADD_DOCTOR_STEP_1",
  REMOVE_DOCTOR_STEP_1: "REMOVE_DOCTOR_STEP_1",
  EDIT_DOCTOR_DETAILS: "EDIT_DOCTOR_DETAILS",
  ADD_EDIT_DOCTOR_STEP_1: "ADD_EDIT_DOCTOR_STEP_1",
  REMOVE_EDIT_DOCTOR_DETAILS: "REMOVE_EDIT_DOCTOR_DETAILS",
  EDIT_PATIENT_DETAILS: "EDIT_PATIENT_DETAILS",
  REMOVE_EDIT_PATIENT_DETAILS: "REMOVE_EDIT_PATIENT_DETAILS",
  SET_DOCTOR_PROFESSIONAL_DETAILS: 'SET_DOCTOR_PROFESSIONAL_DETAILS',
  RESET_DOCTOR_PROFESSIONAL_DETAILS: 'RESET_DOCTOR_PROFESSIONAL_DETAILS',
  SET_ORG_PROFESSIONAL_DETAILS: 'SET_ORG_PROFESSIONAL_DETAILS',
  RESET_ORG_PROFESSIONAL_DETAILS: 'RESET_ORG_PROFESSIONAL_DETAILS',
  SET_CLINIC_PROFESSIONAL_DETAILS: 'SET_CLINIC_PROFESSIONAL_DETAILS',
  RESET_CLINIC_PROFESSIONAL_DETAILS: 'RESET_CLINIC_PROFESSIONAL_DETAILS',
  UPDATE_USER_INFO :'UPDATE_USER_INFO'
};
