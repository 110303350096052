import apiURL from "../constants/apiURL";
import { STORAGE_KEYS } from "../constants/common.constants";
import { apiService } from "./api.service";
import { storageService } from "./storage.service";

const isAuthenticated = () => {
  const token = storageService.getFromLocalStorage(STORAGE_KEYS.AUTH_TOKEN);
  return !!token && token;
};

const isRememberFor30Days = (email) => {
  if (!email) return false;
  const rememberKey = `${STORAGE_KEYS.REMEMBER_DEVICE_FOR_30_DAYS}_${email}`;
  const rememberFor30Days = storageService.getFromLocalStorage(rememberKey);
  return !!rememberFor30Days && rememberFor30Days;
};

const getUserDetails = () => {
  const userData =
    storageService.getFromLocalStorage(STORAGE_KEYS.USER_DETAILS) ?? null;
  return userData;
};

const getUserInDetails = () => {
  const userData =
    storageService.getFromLocalStorage(STORAGE_KEYS.USER_RESET_PASSWORD) ?? null;
  return userData;
};

const logout = () => {
  storageService.removeFromLocalStorage(STORAGE_KEYS.AUTH_TOKEN);
  storageService.removeFromLocalStorage(STORAGE_KEYS.USER_DETAILS);
  storageService.removeFromLocalStorage(STORAGE_KEYS.USER_RESET_PASSWORD);
  // storageService.removeFromLocalStorage(
  //   STORAGE_KEYS.REMEMBER_DEVICE_FOR_30_DAYS
  // );
};

const login = (values) => {
  return apiService.post(apiURL.LOGIN, values);
};

export const setUserDetails = (user) => {
  storageService.setToLocalStorage(STORAGE_KEYS.USER_DETAILS, user);
};

export const setUserInDetails = (user) => {
  storageService.setToLocalStorage(STORAGE_KEYS.USER_RESET_PASSWORD, user);
};

export const setRememberFor30Days = (email, value) => {
  const rememberKey = `${STORAGE_KEYS.REMEMBER_DEVICE_FOR_30_DAYS}_${email}`;
  storageService.setToLocalStorage(rememberKey, value);
};

export const setAuthToken = (token) => {
  storageService.setToLocalStorage(STORAGE_KEYS.AUTH_TOKEN, token);
};

const acceptTermsAndCondition = (id) => {
  return apiService.get(apiURL.ACCEPT_TERMS_AND_CONDITION + id + "/");
};

const verifyOtp = (body) => {
  return apiService.post(apiURL.VERIFY_OTP, body);
};

const resendOtp = (email) => {
  return apiService.get(apiURL.RESEND_OTP, email);
};

const forgotPassword = (email) => {
  return apiService.post(apiURL.FORGOT_PASSWORD, email )
}
const forgotPasswordVerifyOtp = (email) => {
  return apiService.post(apiURL.FORGOT_PASSWORD_OTP, email )
}
const forgotPasswordChange = (body) => {
  return apiService.post(apiURL.FORGOT_PASSWORD_CHANGE, body )
}

export const authService = {
  isAuthenticated,
  isRememberFor30Days,
  getUserDetails,
  logout,
  login,
  acceptTermsAndCondition,
  verifyOtp,
  resendOtp,
  forgotPassword,
  forgotPasswordVerifyOtp,
  forgotPasswordChange,
  getUserInDetails
};
