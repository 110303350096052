import React, { useState, useEffect } from 'react';
import { Col, Row  } from 'react-bootstrap';
import "./style.scss";
import { compareService } from "../../services/compare.service";

function Compare2({patientId, scanId}) {
    const [scanDetails, setScanDetails] = useState(null);
    const [scanDetailsOld, setScanDetailsOld] = useState(null);


    useEffect(() => {
        const fetchScanDetails = async() => {

            const pain_assessment = await compareService.getPainAssessment(scanId);
            
            const scanResult = await compareService.getScanDetails(scanId)
            setScanDetails(scanResult.data.data);

            const imageUrl = `${process.env.PUBLIC_URL}/image.png`;
            const imageResponse = await downloadImage(imageUrl);


            const result2 = await compareService.postPainAssessmentOld(patientId, pain_assessment.data);
            const formData2 = new FormData();
            formData2.append('name', 'image_name');
            formData2.append('pain_id', result2.data);
            formData2.append('file', imageResponse);

            const response2 = await compareService.postImageUploadOld(patientId,formData2);
            setScanDetailsOld(response2.data)
        }
        fetchScanDetails();
    },[patientId, scanId]);


    const downloadImage = async (imageUrl) => {
        const response = await fetch(imageUrl);
        const imageBlob =  await response.blob();
        return imageBlob;
    };

    const getCategoryColor = (categoryName) => {
        const colors = {
            'Head/Jaw': { backgroundColor: '#E7F1FB', bodyPartColor: '#0E6DDB' },
            'Spine/Neck': { backgroundColor: '#E9F7F2', bodyPartColor: '#1CAF7A' },
            'Arm': { backgroundColor: '#FDE9E9', bodyPartColor: '#E62020' },
            'Spine': { backgroundColor: '#FEF1E8', bodyPartColor: '#F07014' },
            'Hip/Knee': { backgroundColor: '#E8F8FC', bodyPartColor: '#26B7E6' },
            'Ankle': { backgroundColor: '#FCECFC', bodyPartColor: '#DC3CDC' },
            'Foot': { backgroundColor: '#E6F1F9', bodyPartColor: '#0070C0' }
        };
        return colors[categoryName] || '';
    };

    const average = (array) => array.reduce((sum, value) => sum + value, 0) / array.length;

    const renderTable = (body_part_summary,mobility_scores) => {

        function roundToNearestHalf(value) {
            if (Number.isInteger(value)) {
                return value;
            }
            
            // Round values between 2.1 and 2.49 to 2.5
            if (value > Math.floor(value) && value <= Math.floor(value) + 0.49) {
                return Math.floor(value) + 0.5;
            }
            
            // Round values between 2.51 and 2.99 to 3
            if (value > Math.floor(value) + 0.5 && value < Math.ceil(value)) {
                return Math.ceil(value);
            }
            
            return value; // For other cases, return the value as is
        }

            const data = [
            {
                category: "Head/Jaw",
                items: [
                    { bodyPart: "Forehead", postureScore: { left: body_part_summary.leftEye , right: body_part_summary.rightEye }, mobility: { left: mobility_scores.leftEye, right: mobility_scores.rightEye } },
                    { bodyPart: "Jaw", postureScore: { left: body_part_summary.leftJaw, right: body_part_summary.rightJaw }, mobility: { left: mobility_scores.leftJaw, right: mobility_scores.rightJaw } },
                ],
            },
            {
                category: "Spine/Neck",
                items: [
                    { bodyPart: "Upper neck", postureScore: { left: body_part_summary.leftEar, right: body_part_summary.rightEar }, mobility: { left: mobility_scores.leftEar, right: mobility_scores.rightEar } },
                    { bodyPart: "Lower neck", postureScore: { left: body_part_summary.leftShoulder, right: body_part_summary.rightShoulder }, mobility: { left: mobility_scores.leftShoulder, right: mobility_scores.rightShoulder } },
                ],
            },
            {
                category: "Arm",
                items: [
                    { bodyPart: "Shoulder", postureScore: { left: body_part_summary.leftShoulder, right: body_part_summary.rightShoulder }, mobility: { left: mobility_scores.leftShoulder, right: mobility_scores.rightShoulder } },
                    { bodyPart: "Elbow", postureScore: { left: body_part_summary.leftElbow, right: body_part_summary.rightElbow }, mobility: { left: mobility_scores.leftElbow, right: mobility_scores.rightElbow } },
                    { bodyPart: "Wrist", postureScore: { left: body_part_summary.leftWrist, right: body_part_summary.rightWrist }, mobility: { left: mobility_scores.leftWrist, right: mobility_scores.rightWrist } },
                    { bodyPart: "Hand", postureScore: { left: body_part_summary.leftWrist, right: body_part_summary.rightWrist }, mobility: { left: mobility_scores.leftWrist, right: mobility_scores.rightWrist } },
                ],
            },
            {
                category: "Spine",
                items: [
                    { bodyPart: "Collar bone", postureScore: { left: average([body_part_summary.leftHip,body_part_summary.leftShoulder]), right: average([body_part_summary.rightHip,body_part_summary.rightShoulder]) }, mobility: { left: average([mobility_scores.leftHip,mobility_scores.leftShoulder]), right: average([mobility_scores.rightHip,mobility_scores.rightShoulder]) } },
                    { bodyPart: "Upper back", postureScore: { left: average([body_part_summary.leftHip,body_part_summary.leftShoulder]), right: average([body_part_summary.rightHip,body_part_summary.rightShoulder]) }, mobility: { left: average([mobility_scores.leftHip,mobility_scores.leftShoulder]), right: average([mobility_scores.rightHip,mobility_scores.rightShoulder]) } },
                    { bodyPart: "Mid back", postureScore: { left: average([body_part_summary.leftHip,body_part_summary.leftShoulder]), right: average([body_part_summary.rightHip,body_part_summary.rightShoulder]) }, mobility: { left: average([mobility_scores.leftHip,mobility_scores.leftShoulder]), right: average([mobility_scores.rightHip,mobility_scores.rightShoulder]) } },
                ],
            },
            {
                category: "Hip/Knee",
                items: [
                    { bodyPart: "Hip", postureScore: { left: body_part_summary.leftHip, right: body_part_summary.rightHip }, mobility: { left: mobility_scores.leftHip, right: mobility_scores.rightHip } },
                    { bodyPart: "Knee cap", postureScore: { left: body_part_summary.leftKnee, right: body_part_summary.rightKnee }, mobility: { left: mobility_scores.leftKnee, right: mobility_scores.rightKnee } },
                    { bodyPart: "Knee", postureScore: { left: body_part_summary.leftKnee, right: body_part_summary.rightKnee }, mobility: { left: mobility_scores.leftKnee, right: mobility_scores.rightKnee } },
                    { bodyPart: "Upper shin", postureScore: { left: body_part_summary.leftKnee, right: body_part_summary.rightKnee }, mobility: { left: mobility_scores.leftKnee, right: mobility_scores.rightKnee } },
                ],
            },
            {
                category: "Ankle",
                items: [
                    { bodyPart: "Lower shin", postureScore: { left: body_part_summary.leftAnkle, right: body_part_summary.rightAnkle }, mobility: { left: mobility_scores.leftAnkle, right: mobility_scores.rightAnkle } },
                    { bodyPart: "Ankle", postureScore: { left: body_part_summary.leftAnkle, right: body_part_summary.rightAnkle }, mobility: { left: mobility_scores.leftAnkle, right: mobility_scores.rightAnkle } },
                ],
            },
            {
                category: "Foot",
                items: [
                    { bodyPart: "Heel", postureScore: { left: body_part_summary.leftHeel, right: body_part_summary.rightHeel }, mobility: { left: mobility_scores.leftHeel, right: mobility_scores.rightHeel } },
                    { bodyPart: "Midfoot", postureScore: { left: body_part_summary.leftFoot, right: body_part_summary.rightFoot }, mobility: { left: mobility_scores.leftFoot, right: mobility_scores.rightFoot } },
                    { bodyPart: "Forefoot", postureScore: { left: body_part_summary.leftFoot, right: body_part_summary.rightFoot }, mobility: { left: mobility_scores.leftFoot, right: mobility_scores.rightFoot } },
                ],
            },
        ];
        return (
            <table className="comparison-table">
                <thead className="comparison-table-row">
                    <tr>
                        <th rowSpan="2" className='comparison-body-part'>Body Part</th>
                        <th colSpan="2" className='comparison-posture-score'>Align Score</th>
                        <th colSpan="2" className='comparison-mobility'>Mobility</th>
                    </tr>
                    <tr className='comparison-left-right'>
                        <th>RIGHT</th>
                        <th>LEFT</th>
                        <th>RIGHT</th>
                        <th className='comparison-right-last'>LEFT</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((category, categoryIdx) => (
                        <React.Fragment key={categoryIdx}>
                            <tr style={{ backgroundColor: getCategoryColor(category.category).backgroundColor }}>
                                <td colSpan="5" className='comparison-category'>{category.category}</td>
                            </tr>
                            {category.items.map((item, itemIdx) => (
                                <tr key={itemIdx}>
                                    <td className='category-bodyPart' style={{ borderBottom: itemIdx === category.items.length - 1 ? 'none' : '1px solid #494949', color: getCategoryColor(category.category).bodyPartColor }}>{item.bodyPart}</td>
                                    <td className={`category-style ${itemIdx === category.items.length - 1 ? 'last-item' : ''}`}>{Math.round(item.postureScore.right)}%</td>
                                    <td className={`category-style ${itemIdx === category.items.length - 1 ? 'last-item' : ''}`}>{Math.round(item.postureScore.left)}%</td>
                                    <td className={`category-style ${itemIdx === category.items.length - 1 ? 'last-item' : ''}`} >{roundToNearestHalf(item.mobility.right)}</td>
                                    <td className='category-mobility'>{roundToNearestHalf(item.mobility.left)}</td>
                                  
                                </tr>
                            ))}
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
        );
    };
    return (
        <Row className="compare-container Scroll">
            <Col md={5}>
                <div className="plan_details">
                    <h4 className='text-white mt-5'>New comparison</h4>
                    <div className='mt-5'>
                       {scanDetails && renderTable(scanDetails.body_part_summary, scanDetails.mobility_scores )}
                    </div>
                </div>
            </Col>
            <Col md={1}></Col>
            <Col md={5}>
                <div className="plan_details">
                    <h4 className='text-white mt-5'>Old comparison</h4>
                    <div className='mt-5'>
                        {scanDetailsOld && renderTable(scanDetailsOld.body_part_summary, scanDetailsOld.estimation_score )}
                    </div>
                </div>
            </Col>
            <Col md={1}></Col>
        </Row>
    );
}
export default Compare2;